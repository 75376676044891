.footer-main {
    color: #ffffff;
    background: #223a70;
}

.footer-main span {
    font-size: 17px;
    line-height: 22px;
    font-weight: normal;
}

.footer-main .brand {
    color: #ffffff;
    font-size: 40px;
    margin-top: 60px;
    font-weight: 300;
    margin-bottom: 40px;
}

.footer-main img {
    height: 60px;
    margin-top: 40px;
    margin-bottom: 70px;
}

.footer-main img:not(:last-of-type) {
    margin-right: 30px;
}

.footer-sub {
    margin-top: 18px;
    line-height: 35px;

    p {
        font-size: 14px;
    }
}

.footer-sub a {
    color: #777777;
}

/* Reponsive */
@media (min-width: 767px) {
    .footer-main span:not(:last-of-type) {
        margin-right: 40px;
    }
}

@media (max-width: 768px) {
    .footer-main .brand {
        font-size: 24px;
    }

    .footer-main span {
        display: block;
    }

    .footer-main img {
        height: 32px;
        margin-right: 5px !important;
    }

    .footer-sub {
        display: none;
    }
}


/*footer {
    color: @white;
    background-color: @main-bg;

    > .container {
        padding-top: 20px;
        padding-bottom: 20px;

        > .row {
            > div:first-of-type {
                padding-right: 39px;
            }
        }
    }

    p {
        line-height: 23px;
    }

    .list-title {
        color: @white;
        font-size: 23px;
        margin-top: 30px;
        font-weight: normal;
        margin-bottom: 25px;
    }

    ul {
        li {
            line-height: 27px;
        }
    }

    .footer-bar {
        padding: 15px 0;
        font-size: 13px;
        font-weight: normal;
    }
}

.footer-logo {
    width: 153px;
    margin-bottom: 10px;
    margin-top: -20px;
}
*/
