@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700');
