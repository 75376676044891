.manager {
    background-color: @greylight-bg;

    .navbar-menu {
        .navbar-nav {
            > li {
                > a {
                    padding: 0 8px;
                    font-size: 13px;
                }
            }
        }
    }

    .btn-yellow.btn-xs {
        padding: 1px 5px;
    }

    .panel:not(.note-editor) {
        box-shadow: @boxshadow;
    }

    [data-confirm="DELETE"]:hover {
        color: @btn-danger-bg;
    }

    .title-bar {
        background-color: @main-bg;
    }

    .navbar.navbar-menu .navbar-brand {
        color: @main;
    }

    .nav-tabs {
        > li {
           margin-bottom: 2px;
        }
    }

    .form-control, .selectize-input input {
        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: #868788;
            font-weight: normal;
        }

        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: #868788;
            opacity: 1;
            font-weight: normal;
        }

        &::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: #868788;
            opacity: 1;
            font-weight: normal;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #868788;
            font-weight: normal;
        }
    }
}

.nestable-slides_active {
    .btn-slide-active {
        display: none;
    }
}

.nestable-slides_inactive {
    .btn-slide-inactive {
        display: none;
    }
}

.radio input[type=checkbox]:checked+label:after, .radio input[type=radio]:checked+label:after {
    top: -5px;
}

.control-label {
    + input[type="checkbox"] {
        margin-left: 10px;
    }
}
