.absolute {
    position: absolute;
}

.inline {
    display: inline;
}

.no-radius {
    border-radius: 0;
}

.dl-spaced {
    dd {
        margin-bottom: 20px;
    }
}

.input-group-no-border {
    .input-group {
        border: 0;

        .form-control, .input-group-addon {
            border: 0;
        }
    }
}

.input-group-no-radius {
    .input-group {
        border-radius: 0;

        .form-control, .input-group-addon {
            border-radius: 0;
        }
    }
}

.input-group-addon {
    border-radius: 2px;

    > i.fa {
        color: @input-group-addon-icon-color;
    }
}

@media (min-width: 767px) {
    .row-eq-height {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        > [class^="col-"] {
            > .panel {
                height: 100%;
                margin-bottom: 0;
            }
        }
    }
}

.input-group-transparent {
    .input-group {
        &.input-daterange,
        &.input-timerange {
            .form-group:first-of-type .form-control {
                border-right: none;
                box-shadow: none !important;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            .form-group:last-of-type .form-control {
                border-left: none;
                box-shadow: none !important;
                border-top-left-radius: 0;
                border-top-right-radius: 4px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 4px;
            }
        }

        &:not(.input-daterange):not(.input-timerange) {
            .form-control {
                border-right: none;
                box-shadow: none !important;
            }
        }

        .input-group-addon {
            background-color: @white;
        }
    }
}

.bg-image {
    background: transparent center / cover no-repeat;
}

.box-shadow {
    box-shadow: @boxshadow;
}

.box-shadow-light {
    box-shadow: @boxshadow-light;
}

.box-shadow-dark {
    box-shadow: @boxshadow-dark;
}

.nav:not(.nav-menu) {
    li {
        a, a:hover, a:focus {
            border: none;
            color: @white;
            font-weight: 700;
            background-color: @main;
            border-radius: @border-radius;
        }

        &.active a, &.active a:hover, &.active a:focus {
            color: @black;
            background-color: @main;
            box-shadow: 0 0 3px rgba(0, 0, 0, .2);
        }
    }
}

/** Forms **/
.form-control {
    padding: 23px 14px;
    border-color: #CECECE;
    box-shadow: 2px 2px 0 -1px #CECECE
}
.form-control {
    //box-shadow: none;
    //border-radius: @border-radius;
    border: 1px solid @input-border;

    height: 47px;
    padding: 10px 14px;
    border-color: #CECECE;
    box-shadow: 2px 2px 0 -1px #CECECE;

    &.no-radius {
        border-radius: 0;
    }

    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: @input-color-placeholder;
        opacity: 1;
        font-weight: normal;
    }

    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: @input-color-placeholder;
        opacity: 1;
        font-weight: normal;
    }

    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: @input-color-placeholder;
        opacity: 1;
        font-weight: normal;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: @input-color-placeholder;
        opacity: 1;
        font-weight: normal;
    }
}

input[type="radio"], input[type="checkbox"] {
    margin: 6px 0 0;
}

.select-styled {
    &:before {
        right: 0;
        background: @white;
        content: "\f078";
        color: #c2c2c2;
        position: absolute;
        display: inline-block;
        text-rendering: auto;
        padding: 0 10px;
        -webkit-transform: translate(0, 50%);
        -moz-transform: translate(0, 50%);
        -ms-transform: translate(0, 50%);
        -o-transform: translate(0, 50%);
        transform: translate(0, 50%);
        -webkit-font-smoothing: antialiased;
        font-family: FontAwesome;
    }

    select::-ms-expand {
        display: none;
    }
}

.input-group-addon {
    color: #8a8a8a;

    &:first-child {
        border-radius: 0;
    }
}

.label-small {
    label {
        font-size: 15px;
        font-weight: normal;
    }
}

label {
    color: @black;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}

.lh-1 {
    line-height: 1;
}

.tags-min-height .selectize-input {
    min-height: 100px;
}

/** Forms **/
.btns-form {
    margin-top: 5px;
    padding-top: 20px;
    border: 0;
    border-top: 1px solid #eeeeee;
}

[data-radio-show] {
    &:not(.show-all) {
        .form-group > .radio {
            display: none;

            &:nth-child(-n+4) {
                display: block;
            }
        }
    }
    &.show-all {
        .show-more {
            display: none;
        }
    }

    .show-more {
        padding: 0 !important;
        color: @greylight-border;
        margin-left: 24px;
        text-decoration: none;
    }
}

.text-light {
    font-weight: 400;
}

.text-normal {
    font-weight: normal;
}

.text-thicker {
    font-weight: 500;
}

.text-thick,
.bold {
    font-weight: bold !important;
}

.list-line {
    list-style-type: none;

    li:before {
        content: "-";
        margin-left: -46px;
        margin-right: 3px;
    }
}

.carousel-control {
    .icon-next, .icon-prev {
        font-family: "FontAwesome";
    }

    .fa-chevron-left:before {
        content: "\f053" !important
    }

    .fa-chevron-right:before {
        content: "\f054" !important
    }
}

.item-default {
    padding: 10px;
    border-radius: @border-radius;

    h5 {
        color: @main;
        margin-top: 5px;
        font-weight: bold;
    }

    + .item-default {
        margin-top: 25px;
    }
}

.nav {
    &.nav-tabs {
        li {
            &:first-of-type {
                a {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }

            &:last-of-type {
                a {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }

            a {
                color: @black;
                border: none;
                border-radius: 0;
                background: @white;
            }

            &.active {
                a, a:focus {
                    color: @white;
                    border: none !important;
                }
            }
        }
    }
}

.navbar {
    margin-bottom: 0;

    &.navbar-menu {
        border: 0;
        border-radius: 0;
        background-color: @main;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .03);

        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;

        li.dropdown.open {
            a {
                //color: @white !important;
                //border-radius: 5px 5px 0 0;
                //background-color: @main-bg !important;

                @media (max-width: 767px) {
                    border-radius: 0;
                    padding-top: 13px;
                    padding-bottom: 13px;
                }
            }
        }

        .dropdown-menu {
            left: -29%;
            color: @white !important;
            padding: 15px 0;
            min-width: 210px;
            border-radius: 0;
            background-color: @main-bg;

            @media (max-width: 767px) {
                padding: 0;
                margin-bottom: 10px;
            }

            &:before {
                display: block;
                position: absolute;
                top: -20.5px;
                right: 10px;
                border-left: 5px solid rgba(0, 0, 0, 0);
                border-right: 5px solid rgba(0, 0, 0, 0);
                border-bottom: 5px solid #2f2f2f;
                color: #fff;
            }

            li {
                margin-bottom: 5px;

                > a {
                    color: @white !important;
                    background-color: @main-bg !important;

                    &:before {
                        content: "-";
                        margin-right: 5px;
                    }
                }
            }
        }

        /**
        TODO: Checken!
         */
        .navbar-brand {
            padding: 0;
            color: @white;
            margin-top: 0;
            margin-bottom: 7px;

            > h1 {
                margin: 0;
                color: @white;
                font-size: 22px;
                font-weight: 300;

                padding: 0 15px;
                margin-top: 15px;
                margin-bottom: 0;
            }

            @media (max-width: 767px) {
                margin-top: 0;

                > h2 {
                    font-size: 16px;
                }
            }
        }

        .navbar-nav {
            margin-top: 10px;
            margin-bottom: 12px;

            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -ms-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;

            @media (min-width: 768px) {
                float: right;
            }

            > li {
                line-height: 5;

                &.active {
                    > a:not(.btn) {
                        color: @white;
                    }
                }
                > a {
                    -webkit-transition: all 0.3s;
                    -moz-transition: all 0.3s;
                    -ms-transition: all 0.3s;
                    -o-transition: all 0.3s;
                    transition: all 0.3s;

                    &:not(.btn) {
                        color: @white;
                    }

                    font-size: 14px;
                    line-height: 3;
                    font-weight: 600;
                    padding: 0 15px;

                    @media (max-width: 767px) {
                        line-height: 1;
                        padding: 11px 8px 5px;
                    }

                    &:hover:not(.btn), &:focus {
                        color: @white;
                        background-color: transparent;
                    }
                }

                &.btn-menu {
                    font-size: 14px;
                    font-weight: bold;
                    padding: 10px 15px;
                    margin-top: 5px;
                    line-height: 1;
                }

                > .dropdown-menu {
                    border: 0
                }
            }
        }

        @media (max-width: 767px) {
            .navbar-collapse {
                background: #fff;
            }
        }

        &.smaller {
            .navbar-brand {
                img {
                    max-width: 200px;
                    margin-top: -10px;
                }
            }

            .navbar-nav {
                margin-top: 10px;
                margin-bottom: 10px;

                > li {
                    line-height: 2;
                }
            }
        }
    }
}

.navbar-toggle {
    border: 1px solid #888888;

    .icon-bar {
        background-color: #888888;
    }
}

/**
 * Buttons
 */
.btn:not(.btn-sm,.btn-xs,.btn-lg) {
    padding: 6px 16px;
}

[data-toggle="collapse"] {
    &:not(.collapsed) {
        .more {
            display: none;
        }
    }

    &.collapsed {
        .less {
            display: none;
        }
    }
}

.btn:hover {
    text-decoration: none;
}

.btn-link, .btn-link-clean {
    &:active, &:focus {
        outline: none !important;
    }
}

.btn-link-clean {

}

.btn-white {
    color: @main;
    border-color: @white;
    background-color: @white;

    &:hover, &:active, &:focus, &:active:focus {
        color: @white;
        border-color: @main-bg;
        background-color: @main-bg;
    }
}

.btn-main.btn {
    color: @white;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    border-color: @btn-main-color;
    background-color: @btn-main-bg;
    box-shadow: @btn-main-box-shadow;

    &:hover, &:active, &:focus, &:active:focus {
        color: @white;
        border-color: @btn-main-color;
        background-color: @btn-main-bg;
        box-shadow: @btn-main-box-shadow;
    }
}

.btn-purple, .btn-feedback {
    color: @white;
    border-color: @purple-border;
    background-color: @purple-bg;

    &:hover, &:active, &:focus, &:active:focus {
        color: @purple;
        border-color: @purple-border;
        background-color: transparent;
    }
}

.btn-success {
    border-color: @green;
    background-color: @green;

    &:hover, &:active, &:focus, &:active:focus {
        border-color: @green;
        background-color: @green;
    }
}

.btn-styled {
    background-color: transparent;
    -webkit-transition: 0.5s; /* Safari */
    transition: 0.5s;
    border-width: 2px;

    &.btn-main {
        color: @main;
        border-color: @main-bg;

        &:hover, &:active, &:focus, &:active:focus {
            color: @white;
            background-color: @main-bg;
        }
    }

    &.btn-info {
        color: @btn-info-bg;
        border-color: @btn-info-border;

        &:hover, &:active, &:focus, &:active:focus {
            color: @white;
            background-color: @btn-info-bg;
        }
    }

    &.btn-default {
        color: @white;
        border-color: @white;

        &:hover, &:active, &:focus, &:active:focus {
            color: @black;
            background-color: @white;
        }
    }

    &.btn-danger {
        color: @btn-danger-bg;
        border-color: @btn-danger-border;

        &:hover, &:active, &:focus, &:active:focus {
            color: @white;
            background-color: @btn-danger-bg;
        }
    }

    &.btn-success {
        color: @green;
        border-color: @green;

        &:hover, &:active, &:focus, &:active:focus {
            color: @white;
            background-color: @green-bg;
        }
    }

    &.btn-white {
        color: @white;
        border-color: @white;

        &:hover, &:active, &:focus, &:active:focus {
            color: @green;
            background-color: @white;
        }
    }
}

.title-bar {
    padding-top: 8px;
    padding-bottom: 16px;
    background-color: @grey-bg;

    h1 {
        color: @white;
        font-size: 26px;
        font-weight: 800;
        font-style: italic;
    }

    .btn-styled {
        font-size: 12px;

        @media (min-width: 768px) {
            font-size: 16px;
            margin-top: 9px;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-bottom: -14px;
            border: 3px solid;
        }
    }
}

.panel-main {
    position: relative;
    border: none;
    box-shadow: 0 1px 2px 0.5px rgba(0, 0, 0, .09);

    .panel-heading {
        padding: 0;

        .panel-image {
            width: 100%;
            height: 188px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background: transparent center / cover no-repeat;
        }

        .panel-title {
            color: @black;
            font-size: 24px;
            font-weight: 300;
        }
    }

    .panel-body {
        padding: 0 17px 15px;

        .title {
            font-size: 26px;
            font-weight: 300;
        }

        p {
            color: #4d4d4d;
            font-size: 16px;
            line-height: 24px;
            font-weight: 300;
        }
    }

    .panel-footer {
        right: 0;
        border: 0;
        bottom: 0;
        padding: 0;
        color: @black;
        position: absolute;
        background-color: @white;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        > a {
            font-weight: 600;

            .btn {
                border: 0;
                margin-left: 9px;
                padding: 7px 21px 7px 12px;
            }
        }
    }
}

.panel {
    border: 0;
    border-radius: @border-radius;

    &.panel-card {
        padding: 0;
        border-radius: 0;
        box-shadow: none;

        &.panel-padderd {
            padding: 10px;
        }

        .panel-title {
            color: @main;
        }

        .panel-body {
            > :first-child {
                margin-top: 0;
            }

            .panel-help {
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid @greylight-bg;
            }
        }

        &.panel-author {
            .panel-body {
                padding: 5px;
                padding-bottom: 15px;

                .col-img {
                    padding: 0 20px 0 20px;
                }

                h6 {
                    font-size: 21px;
                    margin-top: 17px;
                    margin-bottom: 5px;
                }

                .author-text {
                    margin: 0;
                    color: @grey;
                    margin-top: 14px;
                }

                .arrow-small-purple {
                    margin-left: -32px;
                    position: absolute;
                    transform: rotate(-180deg);
                    -ms-transform: rotate(-180deg);
                    -webkit-transform: rotate(-180deg);
                }
            }
        }
    }

    &.panel-clean {
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        border: 1px solid @greylight-border;

        .panel-heading {
            padding: 20px 15px 0;

            .panel-title {
                color: @black;
                font-size: 18px;
                font-weight: 600;
            }
        }

        .panel-body {
            font-size: 15px;
            padding-bottom: 12px;

            p {
                margin: 0;
            }
        }
    }
}

.nav {
    &.nav-pills {
        &.nav-justified {
            li {
                > a {
                    color: @white;
                    margin: 0 5px;
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 0.9px;
                    background-color: @main-bg;
                    border-radius: @border-radius;

                    &:hover {
                        background-color: @main-bg-hover;
                        box-shadow: 0 0 3px rgba(0, 0, 0, .2);
                    }
                }

                &.active {
                    > a {
                        color: @black;
                        background-color: @yellow-bg;
                        box-shadow: 0 0 3px rgba(0, 0, 0, .2);
                    }
                }

                &:first-of-type > a {
                    margin-left: 0;
                }

                &:last-of-type > a {
                    margin-right: 0;
                }

                @media (max-width: @screen-xs-max) {
                    + li {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.p-l-7-5 {
    padding-left: 7.5px;
}

.p-r-7-5 {
    padding-right: 7.5px;
}

.panel-newsletterlist-item {
    margin-bottom: 40px;
    box-shadow: 0 -1px 14px -0.5px rgba(0,0,0,0.2);

    .panel-body {
        .title {
            color: @main;
            font-size: 17px;
            font-weight: bold;
            padding-bottom: 6px;
        }

        padding: 0 9px 14px;

        .btn {
            width: 100%;
            font-size: 12px;
            font-weight: bold;
            line-height: 13px;
            padding: 13px 13px 9px;
            text-transform: uppercase;
        }
    }

    //.panel-footer {
    //    width: 100%;
    //
    //    .btn {
    //        width: 100%;
    //        font-size: 13px;
    //        font-weight: bold;
    //        line-height: 13px;
    //        padding: 13px 13px 9px;
    //        text-transform: uppercase;
    //    }
    //}
}
