.newsletterlist-fluid {
    margin-top: 44px;
    position: relative;
    padding-top: 120px !important;
    padding-bottom: 105px !important;
    background: @main center/cover no-repeat;

    .panel-newsletterlist-item {
        //margin-bottom: 0;
        border-radius: 0 0 4px 4px;

        .panel-body {
            padding-top: 10px;
            padding-bottom: 3px;

            .title {
                font-size: 20px;
                padding-bottom: 13px;
            }
        }

        .row {
            margin: 0;
        }

        .btn-main {
            font-size: 18px;
            padding: 13px 13px 9px;
            line-height: 1.42857143;
        }

        @media(max-width: 767px) {
            .btn-main {
                margin-bottom: 20px;
            }
        }

        @media(min-width: 768px) and (max-width: 1199px) {
            .btn-main {
                font-size: 13px;
                line-height: 27px;
            }
        }
    }
}
